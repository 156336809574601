<template>
	<div>
		Not found
		<router-view />
	</div>
</template>

<script>

export default {
	name: 'not-found',
	components: {}
}
</script>

<style>

</style>
